import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { ProSidebarProvider } from "react-pro-sidebar";
import { QueryClient, QueryClientProvider } from "react-query";
import i18n from "./constant/i18n";
import { I18nextProvider } from "react-i18next";

export const queryCache = new QueryClient({
  defaultOptions: {
    queries: { cacheTime: 5 * 60 * 1000, staleTime: 5 * 60 * 1000 },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      <QueryClientProvider client={queryCache}>
        <ProSidebarProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ProSidebarProvider>
      </QueryClientProvider>
    </React.StrictMode>
  </I18nextProvider>
);

reportWebVitals();
