// CSS
import './App.css';
import "./Assests/Fonts/ENG/Inter-Regular.ttf";

import { useState , useEffect , Suspense , lazy } from 'react';
import { Routes , Route } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// Components
import Loading from 'Components/Loading/Loading';

// Pages
// import { MyCardPane, MyProfilePane } from './Pages/Profile/panes'
// import MyOrderPane from 'Pages/Profile/panes/MyOrderPane/MyOrderPane';
// import MyPointPane from 'Pages/Profile/panes/MyPointPane';
// import MySettingsPane from 'Pages/Profile/panes/MySettingsPane/MySettingsPane';

// Sign In
const LoginPage = lazy(() => import("Pages/Login/Login"));
const LoginEmailPage = lazy(() => import("Pages/Login/LoginEmail/LoginEmail"));
const LoginMobilePage = lazy(() => import("Pages/Login/LoginOTP/LoginOTP"));
const VerifyMemberPage = lazy(() => import("Pages/VerifyMember/index"));

// Sign Up
const RegisterPage = lazy(() =>import("Pages/Register/Register"));

// Service Forget Password
const ForgetpasswordPage = lazy(() => import("Pages/Forgetpassword/Forgetpassword"));
const ConfirmForgetpasswordPage = lazy(() => import("Pages/ConfirmForgetpassword/ConfirmForgetpassword"));

// Landing Pages
const LandingPage = lazy(() => import("Pages/Landing/Landing"));
const SearchPage = lazy(() => import("Pages/Search/SearchPage"));
const ActivityStatusPage = lazy(() => import("Pages/ActivityStatus/ActivityStatus"));
const BookingConfirmPage = lazy(() => import("Pages/BookingConfirm/BookingConfirm"));

// Product Pages
const AllProductPage = lazy(() => import("Pages/AllProduct/AllProduct"));
const AllSouvenirsPage = lazy(() => import("Pages/AllSouvenirs/AllSouvenirs"));
const AllSpecialPage = lazy(() => import("Pages/AllSpecial/AllSpecial"));
const ProductDetailPage = lazy(() => import("Pages/ProductDetail/ProductDetail"));

// Shipping Pages
const MyCartPage = lazy(() => import("Pages/MyCart/MyCart"));
const ShippingPage = lazy(() => import("Pages/Shipping/Shipping"));
const RedeemSuccessPage = lazy(() => import("Pages/RedeemSuccess/RedeemSuccess"));

// Promotion Pages
const PromotionDetailPage = lazy(() => import("Pages/PromotionDetail/PromotionDetail"));

// Info User Pages
const ProfilePage = lazy(() => import("Pages/Profile/ProfilePage"));
const MemberPage = lazy(() => import("Pages/Member/index"));

// Etc Pages
const CommingSoonPage = lazy(() => import("Pages/CommingSoon/index"));

function App() {

  const [ user , setUser ] = useState("");
  const { t } = useTranslation();

  async function getUserData () {
    await axios.get("https://singha-backend.herokuapp.com/getMember" , {
      headers : {
        "x-access-token":localStorage.getItem("SAUTH")
      }
    }).then((res) => {
      setUser(res.data);
    }).catch((err) => {
      localStorage.removeItem("SAUTH");
    })
  }

  useEffect(() => {
    getUserData();
  },[]);

  return (
      <Suspense fallback={<Loading />}>
        <CookieConsent>
          <p>{t('app.cookie.title')} </p> <a style={{color: '#fdbc00'}} target='_blank' href="https://singha-r-sa.org/privacy-policy/">{t('app.cookie.link')}</a>
        </CookieConsent>

        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='/emaillogin' element={<LoginEmailPage />} />
          <Route path='/otplogin' element={<LoginMobilePage  />} />
          <Route path='/member_verification/:token' element={<VerifyMemberPage />} />

          <Route path='/landing' element={<LandingPage />} />
          <Route path='/forgotpassword' element={<ForgetpasswordPage />} />
          <Route path='/confirmForgetpassword/:token' element={<ConfirmForgetpasswordPage />} />
          <Route path='/register' element={<RegisterPage />} />
          <Route path='/search' element={<SearchPage  />} />
          <Route path='/allproduct' element={<AllProductPage />} /> 
          <Route path='/souvenir' element={<AllSouvenirsPage />} /> 
          <Route path='/special' element={<AllSpecialPage />} />
          <Route path='/productdetail/:id' element={<ProductDetailPage />} />
          <Route path='/mycart' element={<MyCartPage />} />
          <Route path='/shipping' element={<ShippingPage user={user} />} />
          <Route path='/promotiondetail/:id' element={<PromotionDetailPage />} />
          <Route path='/activity/:id' element={<ActivityStatusPage user={user} />} />
          <Route path='/redeemsuccess' element={<RedeemSuccessPage />} />
          
          <Route path='/bookingconfirm/:id' element={<BookingConfirmPage />} />

          <Route path='/profile' element={<ProfilePage />} />
  
          <Route path='/user_profile/:id' element={<MemberPage />} />

          <Route path='/commingsoon' element={<CommingSoonPage />} />
        </Routes>
      </Suspense>
  )
}

export default App;
