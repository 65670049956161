export const languages = [
  {
    label: "ภาษาไทย",
    value: "th",
  },
  {
    label: "English",
    value: "en",
  },
];

export const getTranslation = (key) => {
  const text = (en, th) => ({ en, th }[key]);

  return {
    [key]: {
      translation: {
        app: {
          home: {
            title: text("Welcome Back!", "เราดีใจที่ได้เจอคุณอีกครั้ง"),
            sub_title: text(
              "We are happy to see you again.",
              "โปรดระบุ เบอร์โทรศัพท์ หรือ อีเมล เพื่อเข้าสู่ระบบ"
            ),
            phone: text("Phone Number", "เบอร์โทรศัพท์"),
            otp: text("SEND OTP", "ส่ง OTP"),
            or_sing_in_with: text("Or sign in with", "หรือเข้าสู่ระบบโดย"),
            email: text("Email", "อีเมล"),
            dont_member: text(
              "Don't have any accounts yet?",
              "คุณยังไม่ได้เป็นสมาชิกใช่หรือไม่?"
            ),
            register: text("REGISTER", "สมัครสมาชิก"),
          },
          otp: {
            back: text("BACK", "ย้อนกลับ"),
            sign_in: text("Sign In Using OTP", "เข้าสู่ระบบด้วย OTP"),
            resend: text("RESEND OTP", "ส่งรหัส OTP อีกครั้ง"),
            email: text("Email", "อีเมล"),
            password: text("Password", "รหัสผ่าน"),
            forgot_password: text("FORGOT PASSWORD", "ลืมรหัสผ่าน"),
            login: text("Login", "เข้าสู่ระบบ"),
          },
          email: {
            back: text("BACK", "ย้อนกลับ"),
            sign_in: text("Sign In Using Email", "เข้าสู่ระบบด้วย Email"),
            email: text("Email", "อีเมล"),
            password: text("Password", "รหัสผ่าน"),
            please_enter : text("Please Enter" , "โปรดระบุ"),
          },
          register: {
            sub_title: text("Please fill the below to register as Singha R-SA member","โปรดกรอกแบบฟอร์มด้านล่างเพื่อลงทะเบียนเป็นสมาชิก Singha R-SA"),
            next: text("NEXT","ถัดไป"),
            email: text("Email", "อีเมล"),
            back: text("BACK","กลับ"),
            nation: text("Nationality","สัญชาติ"),
            term: text("Terms & Conditions","ข้อตกลงและเงื่อนไข"),
            personal: text("Personal Information","ข้อมูลส่วนตัว"),
            education: text("Education and Aptitude","การศึกษาและความสามารถพิเศษ"),
            review: text("Review Information","ตรวจสอบข้อมูล"),
            lang: text("Language","ภาษา"),
            thai: text("Thai","ไทย"),
            eng: text("English","อังกฤษ"),
            american: text("American","อเมริกัน"),
            china: text("Chinese","จีน"),
            spain: text("Spanish","สเปน"),
            other: text("Other","อื่น"),
            member_info: text("Member Information","ข้อมูลสมาชิก"),
            title: text("Title","คำนำหน้า"),
            title_optional: text("Title (Other)","คำนำหน้า (อื่นๆ)"),
            full_name: text("Fullname","ชื่อ - นามสกุล"),
            nick_name: text("Nickname","ชื่อเล่น"),
            date: text("Date","วันเกิด"),
            address: text("Address","ที่อยู่"),
            country: text("Country","ประเทศ"),
            th: text("Thailand","ประเทศไทย"),
            en: text("America","ประเทศอเมริกา"),
            other : text("Others" , "ประเทศอื่นๆ"),
            require: text("Please enter","โปรดระบุ"),
            number: text("Number","เลขที่"),
            soi: text("Soi","ซอย"),
            region: text("Region","ภูมิภาค"),
            province: text("Province","จังหวัด"),
            district: text("District","เขต/อำเภอ"),
            sub_district: text("Sub-District","แขวง/ตำบล"),
            post_code: text("Post Code","รหัสไปรษณีย์"),
            tel_number: text("Tel.Number","เบอร์โทรศัพท์"),
            social_media: text("Social Media (Optional)","Social Media (Optional)"),
            social_name: text("Social Name (Optional)","ชื่อ Social (Optional)"),
            emergency: text("Emergency Contact (Optional)","ติดต่อกรณีฉุกเฉิน (ไม่บังคับ)"),
            emergency_contact: text("Emergency Contact Person","บุคคิลติดต่อกรณีฉุกเฉิน"),
            relation: text("Relationship","ความสัมพันธ์"),
            edu_apt: text("Education and Aptitude (Optional)","การศึกษาและความสามารถพิเศษ"),
            status: text("Status","สถานะ"),
            person: text("Person","บุคคล"),
            student: text("Student","นักเรียน"),
            edu_lv: text("Education Level","ระดับการศึกษา"),
            academy: text("Academy","สถาบัน"),
            faculty: text("Faculty","คณะ"),
            major: text("Major","สาขา"),
            class_yrs: text("Class Year","ชั้นปี"),
            occupation : text("Occupation" , "อาชีพ"),
            apt: text("Aptitude","ความสามารถพิเศษ"),
            com: text("Computer","คอมพิวเตอร์"),
            sport: text("Sport","กีฬา"),
            music: text("Music","ดนตรี"),
            cook: text("Cook","ทำอาหาร"),
            art: text("Art","ศิลปะ"),
            // lan: text("Language","ภาษา"),
            add_apt: text("ADD APTITUDE","เพิ่มความสามารถพิเศษ"),
            other_info: text("Other Information","ข้อมูลอื่นๆ"),
            blood_type: text("Blood Type","กรุ๊ปเลือด"),
            a: text("A","เอ"),
            b: text("B","บี"),
            ab: text("AB","เอบี"),
            o: text("O","โอ"),
            shirt_size: text("Shirt Size","ขนาดเสื้อ"),
            disease: text("Congenital Disease","โรคประจำตัว"),
            drug_all: text("Drug Allergy","แพ้ยา"),
            food_all: text("Food Allergy","แพ้อาหาร"),
          },
          verify: {
            title: text("Identity Verification","การยืนยันตัวตน"),
            sub_title: text("Please select your preferred methods to receive the verification code.","โปรดเลือกวิธีที่คุณต้องการเพื่อรับรหัสยืนยัน"),
            email: text("Email", "อีเมล"),
            text: text("Text","ข้อความ SMS"),
            success: text("Successfully Registered","ลงทะเบียนเรียบร้อยแล้ว"),
            content1: text("Congratulations, you are officially Singha R-SA member.","ยินดีด้วย! คุณเป็นสมาชิกอย่างเป็นทางการของ Singha R-SA"),
            content2: text("You can now earn points from joining activities, and redeem","ตอนนี้คุณสามารถรับคะแนนจากการเข้าร่วมกิจกรรมและแลกของ"),
            content3: text("rewards on our system.","รางวัลบนเว็บไซด์ของเรา"),
            back: text("GO TO LANDING PAGE","กลับสู่หน้าหลัก")
          },
          term: {
            header: text("Terms and Conditions for Singha R-SA Volunteer Membership","ข้อตกลงและเงื่อนไขของผู้ใช้งานระบบสมาชิกสิงห์อาสา"),
            accept: text("Accept the terms and conditions","ยอมรับข้อตกลงและเงื่อนไข"),
            header2: text("คุณสมบัติการเป็นสมาชิก","คุณสมบัติการเป็นสมาชิก"),
          },
          field: {
            required: text("Please enter", "โปรดระบุ"),
            submit: text("SUBMIT", "ยืนยันเพื่อเข้าสู่ระบบ"),
            error_auth: text(
              "Invalid email or password Please try again.",
              "อีเมลหรือรหัสผ่านไม่ถูกต้อง โปรดลองอีกครั้ง"
            ),
            error_email: text(
              "Format email is invalid , Please enter '@' in email.",
              "รูปแบบของอีเมลไม่ถูกต้อง โปรดใส่เครื่องหมาย ‘@’ ในอีเมลของคุณ"
            ),
            error_password: text(
              "Please enter password",
              "โปรดใส่รหัสผ่านที่ถูกต้อง"
            ),
          },
          contact : {
            contact_us  : text("Contact" , "ติดต่อเรา"),
            sub_contact_us : text("Have and inquery or feedbacks for us?" , "กรอกแบบฟอร์มด้านล่างเพื่อติดต่อทีมงานของเรา"),
            sub_contact_us_2 : text("Fill out thee form below to contact our team." , ""),
            name : text("Name" , "ชื่อ"),
            email : text("Email" , "อีเมล"),
            text_area : text("Message" , "ข้อความ"),
            submit_button : text("Submit" , "ส่ง"),
            address_line_1 : text("999 Thanon Samsen," , "999 ถ.สามเสน แขวง ถนนนครไชยศรี"),
            address_line_2 : text("Thanon Nakhon Chai Si, Dusit District," , "เขต ดุสิต กรุงเทพฯ 10300"),
            address_line_3 : text("Bangkok 10300, Thailand" , "ประเทศไทย"),
            about_us : text("about us" , "เรื่องราวของเรา"),
            volunteer_map : text("volunteer map" , "แผนที่"),
            sign_up : text("sign up" , "สมัครสมาชิก")
          },
          warning_bar : {
            strong_text : text("The enrollment period of this activity has closed." , "ช่วงเวลาการลงทะเบียนของกิจกรรมนี้ปิดลงแล้ว"),
            warning_text : text("Sign up to receive news about our next volunteer opportunity." , "สมัครสมาชิกเพื่อรับข่าวสารเกี่ยวกับการเป็นอาสาสมัครครั้งต่อไปกับเรา"),
            sign_up : text("sign up" , "สมัครสมาชิก")
          },
          activity_page : {
            status : text("STATUS" , "สถานะ"),
            event_open : text("Open" , "เปิด"),
            event_close : text("Close" , "ปิด"),
            activity_date : text("ACTIVITY DATE" , "วันเริ่มกิจกรรม"),
            number_of_people : text("PARTICIPANTS" , "จำนวนที่รับสมัคร")
          },
          activity_card : {
            enroll_ends : text("ENROLLMENT ENDS IN" , "สามารถเข้าร่วมได้จนถึง"),
            enroll_days : text("DAYS" , "วัน"),
            enroll_hour : text("HR" , "ชั่วโมง"),
            enroll_minute : text("MIN" , "นาที"),
            enroll_seconds : text("SEC" , "วินาที")
          },
          landing_navbar : {
            reward : text("REWARDS" , "ของรางวัล"),
            send_story : text("SEND US A STORY" , "ส่งเรื่องราวของคุณ"),
            hello : text("Hello" , "สวัสดี"),
            profile : text("MY PROFILE" , "โปรไฟล์"),
            logout : text("LOG OUT" , "ออกจากระบบ"),
            sign_in : text("Sign In" , "เข้าสู่ระบบ")
          },
          promotion_detail_card : {
            discount_title : text("Special Discount Promotion" , "โปรโมชั่นส่วนลดพิเศษ"),
            featured_product : text("Featured Products" , "สินค้าที่เกี่ยวข้อง"),
            detail : text("Detail" , "รายละเอียด"),
            term_and_condition : text("TERMS&CONDITION" , "ข้อตกลงและเงื่อนไข"),
            term_and_condition_1 : text("Member get upto 15 points with a minimum purchase of 150 points" , "สมาชิกรับคะแนนสูงสุด 15 คะแนน เมื่อซื้อขั้นต่ำ 150 คะแนน"),
            term_and_condition_2 : text("This promotion cannot be used with another special promotion/discounts" , "โปรโมชั่นนี้ไม่สามารถใช้ร่วมกับโปรโมชั่น/ส่วนลดพิเศษอื่นได้"),
            term_and_condition_3 : text("Valid until 5 JAN 2021" , "ใช้ได้ถึงวันที่ 5 ม.ค. 2021"),
          },
          all_product_page : {
            all_product : text("All" , "ของรางวัลทั้งหมด"),
            souvenir : text("Souvenir" , "สินค้าที่ระลึก"),
            special : text("Spacial" , "ของรางวัลพิเศษ"),
            sort_by : text("Sort by" , "จัดลำดับโดย"),
            showmore : text("show more", "แสดงเพิ่มเติม"),
            a_to_z : text("A-Z" , "ก-ฮ"),
            z_to_a : text("Z-A" , "ฮ-ก"),
            low_to_high : text("Low-High" , "คะแนนจากน้อยไปมาก"),
            high_to_low : text("High-Low" , "คะแนนจากมากไปน้อย"),
            apply_button : text("APPLY" , "ตกลง")
          },
          booking_confirm_page : {
            booking_title : text("Booking Confirmation" , "ยืนยันการเข้าร่วมกิจกรรม"),
            booking_sub_title : text("Congratulations, we have put your name into the participant lists. Below is the summary of your activity details." , "ยินดีด้วย เราได้ใส่ชื่อของคุณลงในรายชื่อผู้เข้าร่วมแล้ว ด้านล่างนี้คือสรุปรายละเอียดกิจกรรมของคุณ"),
            activity_title : text("ACTIVITY" , "กิจกรรม"),
            activity_content : text("Sign Up for the First Time at The Singha R-SA Volunteer to Fight thr Northern Forest Fire." , "สมัครครั้งแรกกับสิงห์อาสาเพื่อดับไฟป่าในภาคเหนือ"),
            activity_date : text("ACTIVITY DATE" , "วันเริ่มกิจกรรม"),
            back_to_landing : text("GO TO LANDING PAGE" , "กลับสู่หน้าหลัก")
          },
          footer_component : {
            address_line_1 : text("999 Thanon Samsen," , "999 ถ.สามเสน แขวง ถนนนครไชยศรี"),
            address_line_2 : text("Thanon Nakhon Chai Si, Dusit District," , "เขต ดุสิต กรุงเทพฯ 10300"),
            address_line_3 : text("Bangkok 10300, Thailand" , "ประเทศไทย"),
          },
          my_cart_page : {
            order_summary : text("Order Summary" , "สรุปรายการ"),
            amount : text("Amount" , "ทั้งหมด"),
            point : text("Point" , "แต้ม"),
            points : text("Points" , "แต้ม"),
            shipping : text("Shipping" , "ขนส่ง"),
            subtotal : text("Subtotal" , "ยอดรวม"),
            place_order : text("PLACE ORDER" , "ถัดไป"),
            remark : text("Remark (optional)" , "เพิ่มหมายเหตุ (optional)"),
            specify_remark : text("Specify item detail" , "ระบุรายละเอียด"),
            empty : text("Your Cart is Empty" , "ไม่มีสินค้าในตะกร้า"),
            browse_rewards : text("BROWSE REWARDS" , "ค้นหารางวัล"),
            redeem : text("REDEEM" , "แลกของรางวัล"),
            order_note : text("Order Note" , "หมายเหตุ"),
            contact_information : text("Contact Information" ,"ข้อมูลติดต่อ"),
            email : text("Email" , "อีเมล"),
            shipping_address : text("Shipping Address" , "ที่อยู่"),
            country : text("Country" , "ประเทศ"),
            number : text("Number" , "เลขที่"),
            soi : text("Soi" , "ซอย"),
            region : text("Region" , "ภูมิภาค"),
            province : text("Provinc" , "จังหวัด"),
            district : text("District" , "เขต/ตำบล"),
            sub_district : text("Sub-District" , "แขวง/อำเภอ"),
            post_code : text("Post Code" , "รหัสไปรษรีย์")
          },
          redeem_success_page : {
            success_redeem : text("Successfully Redeemed" , "แลกของรางวัลสำเร็จแล้ว"),
            content : text("Congratulations! You just obtained your reward, reward will be sent to you address." , "ยินดีด้วย! ของรางวัลจะถูกจัดส่งไปยังที่อยู่ของคุณ")
          },
          profile_page : {
            side_bar : {
              title_1 : text("My Card" , "การ์ดของฉัน"),
              title_2 : text("My Profile", "ข้อมูลของฉัน"),
              title_3 : text("My Point History" , "คะแนนของฉัน"),
              title_4 : text("My Order" , "รายการของฉัน"),
              title_5 : text("Settings" , "รหัสของฉัน"),
              home : text("HOME" , "หน้าหลัก"),
              rewards : text("REWARDS" , "ของรางวัล"),
            },
            my_card_plane : {
              title : text("My Card" , "การ์ดของฉัน"),
              member_since : text("Member Since" , "เป็นสมาชิกตั้งแต่"),
              available_point : text("Available Points" , "คะแนนที่มีอยู่"),
              particpated : text("Participated Activities" , "กิจกรรมที่เข้าร่วม")
            },
            my_profile_plane : {
              title : text("My Profile" , "ข้อมูลของฉัน"),
              member_card_title : text("Member Information" , "ข้อมูลสมาชิก"),
              edit_button : text("EDIT" , "แก้ไข"),
              my_profile_card : {
                nationality : text("Nationality" ,"สัญชาติ"),
                title : text("Title" , "คำนำหน้า"),
                fullname : text("Fullname" , "ชื่อ"),
                nickname : text("Nickname" ,"ชื่อเล่น"),
                date : text("Date" , "วันเกิด"),
                tel_number : text("Tel. Number" , "เบอร์โทรศัพท์"),
                email : text("Email" , "อีเมล"),
                socail_media : text("Socail Media (Optional)" , "ช่องทางโซเชียล (Optional)"),
                socail_name : text("Socail Name (Optional)", "ชื่อโซเชียล (Optional)"),
                address : text("Address" , "ที่อยู่"),
                country : text("Country" , "ประเทศ"),
                emergency_contact : text("Emergency Contact (Optional)" , "ติดต่อกรณีฉุกเฉิน (Optional)"),
                emergency_person : text("Emergency Contract Person" , "บุคคลติดต่อกรณีฉุกเฉิน"),
                relationship : text("Relationship" , "ความสัมพันธ์"),
                education : text("Education and Aptitude (Optional)" , "การศึกษาและความสามารถพิเศษ"),
                status : text("Status" , "สถานะ"),
                education_level : text("Education Level" , "ระดับการศึกษา"),
                falculty : text("Falculty" , "สาขา"),
                major : text("Major" , "คณะ"),
                class_year : text("Class Year" , "ชั้นปี"),
                aptitude : text("Aptitude" , "ความสามารถพิเศษ"),
                other_information : text("Other Information" , "ข้อมูลอื่นๆ"),
                blood_type : text("Blood Type" , "กรุ๊ปเลือด"),
                shirt_size : text("Shirt Size" ,"ขนาดเสื้อ"),
                congenital_disease : text("Congenital Disease" , "โรคประจำตัว"),
                drug_allergy : text("Drug Allergy" , "แพ้ยา"),
                food_allergy : text("Food Allergy" , "แพ้อาหาร")
              }
            },
            my_point_plane : {
              title : text("My Point History" , "คะแนนของฉัน"),
              all : text("All" , "ทั้งหมด"),
              received : text("Received" , "ได้รับแล้ว"),
              redeemed : text("Redeemed" , "แลกของรางวัล")
            },
            my_order_plane : {
              title : text("My Order" , "รายการของฉัน"),
              order_code : text("Order Code" , "หมายเลขคำสั่งซื้อ"),
              delivery : text("Delivery" , "ผู้จัดส่ง"),
              tracking_no : text("Tracking no." , "หมายเลขการติดตาม"),
              shipping : text("Shipping" , "กำลังจัดส่ง"),
              redeem_complete : text("Redeem Completed" , "แลกสำเร็จแล้ว"),
              delivered : text("Delivered" , "ส่งสำเร็จแล้ว"),
            },
            my_settings_plane : {
              title : text("My Settings" , "รหัสของฉัน"),
              reset_password : text("Reset Password" , "ตั้งค่ารหัสผ่าน"),
              password : text("Password" , "รหัสผ่าน"),
              edit_button : text("Edit" , "แก้ไข"),
              new_password : text("New Password" , "รหัสผ่านใหม่"),
              please_enter : text("Please Enter" , "โปรดระบุ"),
              require_submit : text("Must be at least 6 characters" , "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร"),
              cancel_button : text("CANCEL" , "ยกเลิก"),
              update_button : text("UPDATE" , "อัพเดท")
            }
          },
          product_detail_page : {
            prodict_detail_cart : {
              add_to_cart : text("ADD TO CARD" , "ใส่ตะกร้า")
            },
            related_title : text("Related Products" , "สินค้าที่เกี่ยวข้อง")
          },
          search_page : {
            title : text("Search" , "ค้นหา"),
            search_input : text("Search Rewards" , "ค้นหา"),
            list_1 : text("Souvenirs" , "ของที่ระลึก"),
            list_2 : text("Food & Beverage" , "อาหารและเครื่องดื่ม"),
            result : text("Result" , "ผลลัพธ์")
          },
          cookie: {
            title: text("We use cookies on our website to enhance user experience. For more information you can read our ","  We use cookies on our website to enhance user experience. For more information you can read our "),
            link: text("policy here.","policy here."),
          },
          errorMessage : {
            error_message_01 : text("Please Enter" , "กรุณาระบุข้อมูล"),
            error_message_02 : text(""),
            error_email : text("Please enter a valid email" , "อีเมล์ไม่ถูกต้อง") 
          },
          forgot_password_page : {
            title : text("Forgot Password" , "ลืมรหัสผ่าน"),
            select_solution : text("Please select your preferred methods to receive the verification code." , "กรุณาเลือกวิธีการรับรหัสยืนยัน"),
            email : text("Email" , "อีเมล"),
            phone : text("Phone Number" , "เบอร์โทรศัพท์"),
            errorStepOne : text("Please select your chosie." , "กรุณาเลือกวิธีการกู้รหัสผ่าน"),
            next : text("Next" , "ต่อไป"),
            back : text("Back" , "กลับ"),
            please_enter : text("Please enter your" , "โปรดระบุ"),
            input_text2 : text("to receive the code." , "เพื่อรับรหัสยืนยัน"),
            email_invalid : text("Invalid email." , "อีเมลไม่ถูกต้อง"),
            phone_invalid : text("Invalid phone number." , "เบอร์โทรศัพท์ไม่ถูกต้อง"),
            verify_title : text("Verify Identity" , "ยืนยันตัวตน"),
            verify_otp_1 : text("We have sent the code to you via" , "เราได้ส่งรหัสให้คุณผ่านทางอีเมล"),
            verify_otp_2 : text("your email Please kindly check" , "กรุณาตรวจสอบข้อความมือถือของคุณ"),
            verify_otp_3 : text("your mobile message." , ""),
            resend_otp : text("RESEND OTP" , "ส่งรหัสอีกครั้ง"),
            errorStepOtp : text("Please enter a valid code." , "รหัสยืนยันไม่ถูกต้อง"),
            change_password : text("Change Password" , "เปลี่ยนรหัสผ่าน"),
            new_password : text("New Password", "รหัสผ่านใหม่"),
            require_password : text("Must be at least 6 characters" , "รหัสผ่านต้องไม่น้อยกว่า 6 ตัวอักษร"),
            complete_one : text("Your new password" , "การตั้งรหัสผ่านใหม่"),
            complete_two : text("is all set." , "สำเร็จแล้ว"),
            complete_three : text("Congratulations, we have successfully" , "ยินดีด้วย ระบบทำการเปลี่ยนแปลง"),
            complete_four : text("created your new password." , "รหัสผ่านใหม่เรียบร้อยแล้ว"),
            to_landing : text("GO TO LANDING PAGE" , "กลับหน้าหลัก")
          }
        },
      },
    },
  };
};
