// import axios from "axios";
// import { useQuery } from "react-query";

// export const useGetUser = () => {
//     return useQuery("getMember", () => {
//       return axios
//         .get(`https://singha-backend.herokuapp.com/getMember`, {
//           headers: {
//             "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Nzk0LCJpYXQiOjE2NzIwNjM1NDgsImV4cCI6MTg3NDY1NTU0OH0.ae3d3LpkCHLjB3v6WKv13I45hkAMOoLxqSq5dJuAJIo",
//           },
//         })
//         .then((res) => res.json())
//         .catch((error) => {
//           // window.location.href = "/";
//         });
//     },
//     {
//       // The query will not execute until the userId exists
//       enabled: !!localStorage.getItem("SAUTH"),
//     }
//     );

//   // } else {
//   //   return null;
//   // }
// };


export const getLanguage = () => {
    return localStorage.getItem('LANGUAGE') || 'th'
}


export const setLanguage = (lang) => {
    localStorage.setItem('LANGUAGE', lang)
}